export default class ScrollObserver {
  private lastScrollTop = 0;
  private isScrolling = false;
  private delay = 200;
  private sstimer = null;

  private static _instance: ScrollObserver = null;

  private constructor() {}

  public static get instance(): ScrollObserver {
    if (!this._instance) {
      this._instance = new ScrollObserver();
    }
    return this._instance;
  }

  start(fn: () => void) {
    window.addEventListener("scroll", () => {
      const currentScrollY = window.scrollY;
      if (this.lastScrollTop !== currentScrollY && this.isScrolling === false) {
        this.lastScrollTop = currentScrollY;
        this.isScrolling = true;
        fn();
      }
    });
  }

  stop(fn: () => void) {
    window.addEventListener("scroll", () => {
      if (this.sstimer) {
        clearTimeout(this.sstimer);
      }
      this.sstimer = setTimeout(() => {
        this.isScrolling = false;
        fn();
      }, this.delay);
    });
  }
}
