import bugsnagClient from "./lib/Bugsnag";
import { isAvailableLocalStorage } from "./lib/localstorage-util";
import ScrollObserver from "./lib/ScrollObserver";

export default class Application {
  main() {
    console.log("Redseal start...", process.env.NODE_ENV);
    bugsnagClient;

    if (window.innerWidth < 768) {
      const header = document.querySelector(".header_container_wrapper");
      const bottomNavigation = document.querySelector("#bottom_navigation");

      ScrollObserver.instance.start(() => {
        if (header) {
          header.classList.remove("scrollstop_visibility");
          header.classList.add("scrollstart_visibility");
        }

        if (bottomNavigation) {
          bottomNavigation.classList.remove("scrollstop_visibility");
          bottomNavigation.classList.add("scrollstart_visibility");
        }
      });
      ScrollObserver.instance.stop(() => {
        if (header) {
          header.classList.remove("scrollstart_visibility");
          header.classList.add("scrollstop_visibility");
        }

        if (bottomNavigation) {
          bottomNavigation.classList.remove("scrollstart_visibility");
          bottomNavigation.classList.add("scrollstop_visibility");
        }
      });
    }

    // LocalStorageが利用可能かどうか
    const availableLocalStorage = isAvailableLocalStorage();
    console.log("LocalStorage is ", availableLocalStorage);
    window.isAvailableLocalStorage = availableLocalStorage;
  }
}
