export const isAvailableLocalStorage = (): boolean => {
  if (typeof localStorage !== "undefined") {
    try {
      localStorage.setItem("dummy", "1");
      if (localStorage.getItem("dummy") === "1") {
        localStorage.removeItem("dummy");
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  } else {
    return false;
  }
};
